import React, { useState, useRef, useMemo, useEffect } from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { isPc } from 'utils/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import preBtn from './img/preBtn.png'
import nextBtn from './img/nextBtn.png'
import classnames from 'classnames'

interface ArrayControlProps {
  /**数组列表 */
  arrayList: any
  tip?: string
}

const ArrayControl: React.FC<ArrayControlProps> = (props) => {
  const { arrayList, tip = '' } = props
  const isMb = useIsWindowWidthSmaller()
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef<any>(null)
  const [pcIndex, setPcIndex] = useState(0)
  useEffect(() => {
    swiperRef.current.slideTo(pcIndex)
  }, [pcIndex])

  const slideChange = (num) => {
    if (pcIndex + num < 0 || pcIndex + num > arrayList.length) {
      return
    } else setPcIndex(pcIndex + num)
  }

  const node = useMemo(() => {
    if (isMb) {
      const dataArr: any = []
      for (let index = 0; index < arrayList.length; index++) {
        if (index % 2 === 1 && index !== 0) {
          const item = [arrayList[index - 1], arrayList[index]]
          dataArr.push(item)
        }
        if (index % 2 === 0 && index === arrayList.length - 1) {
          const item = [arrayList[index]]
          dataArr.push(item)
        }
      }

      return (
        <div>
          <Swiper
            onSlideChange={({ activeIndex }) => {
              setActiveIndex(activeIndex)
            }}
          >
            {dataArr.map((itemArr) => {
              return (
                <SwiperSlide>
                  {itemArr.map((item, index) => {
                    return (
                      <div style={{ marginTop: index === 0 ? '0' : '5rem' }}>
                        <div className={styles.arrayItem}>
                          <div className={styles.avatar}>
                            <img src={item.avatar.publicURL ?? item.avatar} />
                            <div className={styles.introduction}>{item.introduction}</div>
                          </div>
                          <div className={styles.words}>
                            <div className={styles.name}>{item.name}</div>
                            <div className={styles.desc}>{item.desc}</div>
                          </div>
                        </div>
                        <div className={styles.mbIntroduction}>{item.introduction}</div>
                      </div>
                    )
                  })}
                </SwiperSlide>
              )
            })}
          </Swiper>
          {tip && <div className={styles.mbTip}>{tip}</div>}
          <div className={styles.bottom}>
            {dataArr.map((item, index) => {
              return (
                <div
                  className={classnames(styles.bottomItem, {
                    [styles.bottomItemActive]: activeIndex === index,
                  })}
                ></div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ width: '1000px', marginLeft: '59px', marginRight: '59px' }}>
          <Swiper
            slidesPerView={!isPc() ? 1 : 4}
            spaceBetween={!isPc() ? 0 : 50}
            onSwiper={(swiperInstance) => (swiperRef.current = swiperInstance)}
            noSwiping
          >
            {arrayList.map((item, index) => {
              return (
                <SwiperSlide className="swiper-no-swiping">
                  <div key={item.name}>
                    <div className={styles.arrayItem}>
                      <div className={styles.avatar}>
                        <img src={item.avatar.publicURL ?? item.avatar} />
                        <div className={styles.introduction}>{item.introduction}</div>
                      </div>
                      <div className={styles.words}>
                        <div className={styles.name}>{item.name}</div>
                        <div className={styles.desc}>{item.desc}</div>
                      </div>
                    </div>
                    <div className={styles.mbIntroduction}>{item.introduction}</div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {tip && <div className={styles.tip}>{tip}</div>}
        </div>
      )
    }
  }, [isMb, activeIndex, arrayList])

  return (
    <div className={styles.arrayControlWrapper}>
      <div
        className={styles.btn}
        onClick={() => {
          slideChange(-4)
          // swiperRef.current.slideTo(0)
        }}
      >
        <img src={preBtn} />
      </div>
      {node}
      <div
        className={styles.btn}
        onClick={() => {
          slideChange(+4)
          // swiperRef.current.slideTo(4)
        }}
      >
        <img src={nextBtn} />
      </div>
    </div>
  )
}

export default ArrayControl
