import React, { ReactNode } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface ButtonProps {
  className?: string
  children?: ReactNode
  href?: string
  onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ className, children, href, onClick }) => {
  return (
    <a
      href={href}
      className={classnames(
        styles.button,
        ' flex w-fit   justify-center items-center py-[8px] px-[16px] text-[14px]',
        className,
      )}
      onClick={onClick}
    >
      <span className="leading-[14px]">{children}</span>
      <span className="button-icon ml-[7px]" />
    </a>
  )
}

export default Button
