import React, { useState } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface agenda_head_item {
  title: string
  date: string
  time: string
  desc: string
}

interface AgendaModuleProps {
  className?: string
  agenda_head: agenda_head_item[]
  dataSource?: any
}

const AgendaModule: React.FC<AgendaModuleProps> = ({ className, agenda_head, dataSource }) => {
  const [active, setActive] = useState<number>(0)
  const [hoverIndex, setHoverIndex] = useState<number>(0)

  return (
    <div
      className={classnames(styles.container, className, 'lg:w-[1140px] text-[#fff] w-full px-[20px] lg:px-0 mx-auto')}
    >
      <div className=" overflow-x-scroll">
        <div className="agenda-head min-w-[600px] flex    lg:justify-between">
          {agenda_head?.map(({ title, date }, index) => {
            return (
              <div
                className={classnames(
                  'flex justify-center items-center lg:block mr-[14px] lg:mr-0   px-[20px] lg:px-0 h-[32px] lg:h-auto lg:w-[32%] lg:py-[18px] agenda-head-item  text-[#fff] text-center',
                  {
                    'agenda-head-item-active': active === index,
                  },
                )}
                key={title}
                onClick={() => setActive(index)}
              >
                <p className={classnames('text-[16px] lg:text-[24px] font-medium ')}>{title}</p>
                <p className="text-[14px] hidden lg:block ">{date}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className="lg:mt-[30px] mt-[20px]"
        onMouseLeave={() => {
          setHoverIndex(0)
        }}
      >
        <div className="mb-card lg:hidden px-[20px] py-[20px] mb-[10px]">
          <p className="text-[18px] font-medium">{agenda_head[active]?.time}</p>
          <p className="text-[14px] font-medium">{agenda_head[active]?.desc}</p>
        </div>
        {dataSource[active]?.data?.map((item, index) => {
          return (
            <div
              className={classnames(
                'px-[20px] lg:px-[56px]  py-[20px] lg:py-[36px] lg:flex agenda_card lg:mb-[24px] mb-[10px]',
                { first_agenda_card: hoverIndex === index },
              )}
              key={index}
              onMouseEnter={() => {
                setHoverIndex(index)
              }}
            >
              <div className="left-card lg:w-[200px] lg:flex items-start">
                <span className="w-[16px] inline-block h-[16px] lg:w-[28px] lg:h-[28px] left-card-icon"></span>
                <span className="ml-[8px] lg:ml-[14px] text-[18px] leading-[16px] lg:leading-[28px] lg:text-[24px]">
                  {item?.time}
                </span>
              </div>
              <div className="right-card lg:flex-1">
                <p className="text-[18px] lg:text-[24px] lg:leading-[30px] font-medium whitespace-pre-wrap">
                  {item?.title}
                </p>
                {!!item?.desc && (
                  <p className="text-[14px] mt-[10px] lg:mt-[18px] whitespace-pre-wrap ">{item?.desc}</p>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AgendaModule
