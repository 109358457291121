// extracted by mini-css-extract-plugin
export var btn = "index-module--btn--B4L9d";
export var content = "index-module--content--soOKA";
export var defaultCard = "index-module--defaultCard--dLkcE";
export var desc = "index-module--desc--ujj9t";
export var detailedExpansionCard = "index-module--detailedExpansionCard--i9r5H";
export var expansionCard = "index-module--expansionCard--6VwVn";
export var header = "index-module--header--ZpfFb";
export var headerMb = "index-module--headerMb--8LvTt";
export var lastBtn = "index-module--lastBtn--8n-Jb";
export var serial = "index-module--serial--l9suh";
export var serialWrapper = "index-module--serialWrapper--Y-+5h";
export var space = "index-module--space--GS5Lw";
export var title = "index-module--title--bNh2u";
export var words = "index-module--words--MtiAo";