import React from 'react'
import * as styles from './index.module.less'
import dateIcon from './img/dateIcon.png'
import locationIcon from './img/locationIcon.png'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface BannerProps {
  /**banner图片 */
  img: any
  /**banner文字图片 */
  wordImg?: any
  /**移动端banner图片 */
  mbImg?: any
  /**日期 */
  date?: string
  /**地点 */
  location?: string
  /**左边按钮文字 */
  leftButtonText?: string
  /**左边按钮链接 */
  leftLink?: string
  /**右边按钮文字 */
  rightButtonText?: string
  /**右边按钮链接 */
  rightLink?: string
}

const Banner: React.FC<BannerProps> = (props) => {
  const {
    img,
    wordImg,
    mbImg,
    date,
    location,
    leftButtonText = '北京线下报名',
    leftLink,
    rightButtonText = '预约线上直播',
    rightLink,
  } = props

  const isMb = useIsWindowWidthSmaller()

  return (
    <div className={styles.topicBannerWrapper}>
      <div className={styles.imgWrapper}>
        <img src={isMb ? mbImg.publicURL ?? mbImg : img.publicURL ?? img} />
        {wordImg && !isMb && <img src={wordImg.publicURL ?? wordImg} className={styles.wordImg} />}
      </div>
      <div className={styles.words}>
        <div className={styles.date}>
          <img src={dateIcon} className={styles.dateIcon} />
          <div className={styles.dateWord}>{date}</div>
        </div>
        <div className={styles.location}>
          <img src={locationIcon} className={styles.locationIconIcon} />
          <div className={styles.locationIconWord}>{location}</div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <div className={styles.buttonItem}>
          {leftButtonText}
          <a href={leftLink}></a>
        </div>
        <div className={styles.buttonItem}>
          {rightButtonText}
          <a href={rightLink}></a>
        </div>
      </div>
    </div>
  )
}

export default Banner
