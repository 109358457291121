import React from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'
import Button from '../Button'

interface CommunityProps {
  className?: string
  dataSource: {
    title: string
    desc: string
    buttons: {
      title: string
      href: string
    }[]
  }
}
const Community: React.FC<CommunityProps> = ({ className, dataSource }) => {
  const { title, desc, buttons } = dataSource
  return (
    <div className={classnames(styles.container, className)}>
      <div className="lg:w-[1200px] lg:py-[60px] pt-[20px] pb-[120px] px-[20px] lg:px-0 mx-auto text-[#fff]">
        <p className="text-[18px] lg:text-[30px]  font-medium">{title}</p>
        <p className="mt-[8px] text-[14px]">{desc}</p>
        <p className="mt-[40px] flex flex-wrap">
          {buttons?.map((item) => {
            return (
              <Button className="mr-[20px] mb-[20px] lg:mb-0" href={item?.href} key={item?.href}>
                {item?.title}
              </Button>
            )
          })}
        </p>
      </div>
    </div>
  )
}

export default Community
