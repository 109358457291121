import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useScroll } from 'ahooks'
import Layout from 'layouts/layout'
import { Seo } from 'components/index'
import Nav from 'components/Topic/Ds2023/Nav'
import Banner from 'components/Topic/Ds2023/Banner'
import DetailedExpansionCard from 'components/Topic/Ds2023/DetailedExpansionCard'
import ArrayControl from 'components/Topic/Ds2023/ArrayControl'
import Section from 'components/Topic2023/Section'
import AgendaModule from 'components/Topic2023/AgendaModule'
import Community from 'components/Topic2023/Community'
import Ds2023ReviewSwiper from 'components/Topic2023/Ds2023ReviewSwiper'
import Ds2023Footer from 'components/Topic2023/Ds2023Footer'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'

const DS2023 = (props) => {
  const { data } = props
  const { ds2023Yaml } = data
  const { nav, banner, detailedExpansionCard, arrayControl, section3, section4, section5, section6 } = ds2023Yaml
  const rightPoint = useRef<any>()
  const [flag, setFlag] = useState<boolean>(false)
  const position = useScroll(null)
  const { top = 0 } = position ?? {}
  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="神策数据 | 2023数据驱动大会| 新旅程新经营,决胜数字化"
        description="第八届神策数据驱动大会，是国内数字化转型和营销科技领域的年度行业盛会，聚焦全球前沿认知，分享各行业、企业的数字化经营实践，共同构建围绕客户旅程的数字融合新生态。"
        keywords="2023数据驱动大会,第八届数据驱动大会,神策数据驱动大会"
      />

      <div className={styles.ds2023Root}>
        <Nav {...nav} />
        <Banner {...banner} />
        {/* 大会亮点 */}
        <Section title={detailedExpansionCard?.title} mask_bg={detailedExpansionCard?.titleBg?.publicURL}>
          <DetailedExpansionCard cardArr={detailedExpansionCard.data} />
        </Section>
        {/* 重磅嘉宾 */}
        <Section title={arrayControl?.title} mask_bg={arrayControl?.titleBg?.publicURL}>
          <ArrayControl arrayList={arrayControl.data} tip={arrayControl.tip} />
          {/* <div className={styles.arrayControlTip}>嘉宾陆续更新中……</div> */}
        </Section>

        {/* 大会议程 */}
        <Section className="lg:pb-[66px]" title={section3?.title} mask_bg={section3?.mask_bg?.publicURL}>
          <AgendaModule agenda_head={section3?.agenda_head} dataSource={section3?.agenda_dataSource} />
        </Section>
        {/* 加入社区 */}
        <Section className="mx-[20px] lg:mx-0" title={section4?.title} mask_bg={section4?.mask_bg?.publicURL}>
          <Community dataSource={section4?.dataSource} />
        </Section>
        {/* 往届回顾 */}
        <Section
          mask_className="!h-[26px] !top-[10px] lg:!h-[50px]"
          title={section5?.title}
          mask_bg={section5?.mask_bg?.publicURL}
        >
          <div className="lg:w-[1200px] mx-auto lg:px-0 px-[20px]">
            <Ds2023ReviewSwiper dataSource={section5?.review_dataSource} />
          </div>
        </Section>
        {/* 生态合作伙伴 */}
        <Section
          mask_className="lg:!h-[65px] !top-0 !h-[44px]"
          title={section6?.title}
          mask_bg={section6?.mask_bg?.publicURL}
        >
          <div className="lg:w-[1050px] mx-auto">
            {section6?.dataSource?.map((item) => (
              <div className="lg:flex text-[#fff]  lg:mb-[18px] mx-[20px] lg:mx-0" key={item?.title}>
                <div className="text-[18px] lg:leading-[80px] lg:text-[30px] lg:mr-[30px] font-medium mt-[3rem] lg:mt-0">
                  {item?.title}
                </div>
                <div className="flex flex-1 flex-wrap">
                  {item?.icons?.map((items, index) => {
                    return (
                      <div
                        key={'items' + index}
                        className="lg:mx-[11px] mt-[10px] mb-[8px] lg:mb-[22px] mr-[6px] lg:mt-0 lg:rounded-[12px] w-[105px] h-[42px] lg:w-[202px] lg:h-[80px] item-logo"
                      >
                        <img
                          className="w-full h-full"
                          src={isMb ? items?.icon?.mb?.publicURL : items?.icon?.pc?.publicURL}
                          alt={item?.title}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="footer-card lg:mt-[140px] mt-[80px] lg:w-[1202px] lg:mx-auto mx-[20px] rounded-[12px]">
            <img
              className="w-full lg:inline-block hidden"
              src={require('assets/images/topic/ds2023/footer_pc.png').default}
              alt="2023驱动大会"
            />
            <img
              className="w-full lg:hidden  "
              src={require('assets/images/topic/ds2023/footer_mb.svg').default}
              alt="2023驱动大会"
            />
          </div>
        </Section>
        <Ds2023Footer />
        {/* 右侧小数点 */}
        <div
          ref={rightPoint}
          className={classnames(
            ' fixed hidden z-[999] w-[48px] min-h-[300px]  top-[50%] translate-y-[-50%] right-[28px]  lg:flex flex-col',
            styles.rightNav,
            { 'opacity-0': top < 700 },
          )}
        >
          <div
            className={classnames('h-[66px] w-full', styles.rightPoint)}
            onClick={() => {
              setFlag(!flag)
            }}
          ></div>
          <div className={classnames('w-full  rounded-[24px] ', styles.rightBtn, { blockFlag: flag })}>
            <div className=" relative">
              <a href="http://ads-link.sensorsdata.cn/t/uYRs" target="_blank">
                签 约 客 户 认 证
              </a>
            </div>
            <div className="relative mt-[20px] mb-[14px]">
              <span>咨 询</span>
              <p className={classnames('absolute pt-[24px] px-[24px] text-center left-[-200px]', styles.consult)}>
                <h3 className="text-center ">
                  <img
                    className="w-[140px] h-[140px]"
                    src={require('assets/images/topic/ds2023/consulting_conference_qr_code.png').default}
                    alt=""
                  />
                </h3>
                <h3 className=" text-[14px] leading-[18px]   my-[13px]">扫码咨询</h3>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    ds2023Yaml {
      nav {
        signUpLink
        appointmentLink
      }
      banner {
        img {
          publicURL
        }
        wordImg {
          publicURL
        }
        mbImg {
          publicURL
        }
        date
        location
        leftButtonText
        leftLink
        rightButtonText
        rightLink
      }
      detailedExpansionCard {
        title
        titleBg {
          publicURL
        }
        data {
          serial
          title
          desc
          content
          btnLink
          defaultBg {
            publicURL
          }
          expansionBg {
            publicURL
          }
          btnBg {
            publicURL
          }
          btnBgMb {
            publicURL
          }
          mbBg {
            publicURL
          }
        }
      }
      arrayControl {
        title
        titleBg {
          publicURL
        }
        tip
        data {
          avatar {
            publicURL
          }
          introduction
          name
          desc
        }
      }
      section3 {
        title
        mask_bg {
          publicURL
        }
        agenda_head {
          title
          date
          time
          desc
        }
        agenda_dataSource {
          data {
            time
            title
            desc
          }
        }
      }
      section4 {
        title
        mask_bg {
          publicURL
        }
        dataSource {
          title
          desc
          buttons {
            title
            href
          }
        }
      }
      section5 {
        title
        mask_bg {
          publicURL
        }
        review_dataSource {
          number
          link
          img_url {
            childImageSharp {
              gatsbyImageData(formats: JPG)
            }
          }
          year
          desc
        }
      }
      section6 {
        title
        mask_bg {
          publicURL
        }
        dataSource {
          title
          icons {
            icon {
              pc {
                publicURL
              }
              mb {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default DS2023
