// extracted by mini-css-extract-plugin
export var arrayControlWrapper = "index-module--arrayControlWrapper--cuwiy";
export var arrayItem = "index-module--arrayItem--AdO42";
export var avatar = "index-module--avatar--2Gn2u";
export var bottom = "index-module--bottom--BEAJz";
export var bottomItem = "index-module--bottomItem--YEB3H";
export var bottomItemActive = "index-module--bottomItemActive--tdkZU";
export var btn = "index-module--btn--oONBI";
export var desc = "index-module--desc--ybpFm";
export var introduction = "index-module--introduction--EF5V5";
export var mbIntroduction = "index-module--mbIntroduction--2++Ce";
export var mbTip = "index-module--mbTip--qp+sm";
export var name = "index-module--name--wWV6R";
export var tip = "index-module--tip--hRbBr";
export var words = "index-module--words--ghReW";