// extracted by mini-css-extract-plugin
export var buttonItem = "index-module--buttonItem--CWOxK";
export var buttonsWrapper = "index-module--buttonsWrapper--BpvAC";
export var date = "index-module--date--6qIfi";
export var dateIcon = "index-module--dateIcon--JKoPi";
export var dateWord = "index-module--dateWord--RxsBK";
export var imgWrapper = "index-module--imgWrapper--QS+hc";
export var location = "index-module--location--1pEI6";
export var locationIconIcon = "index-module--locationIconIcon--XVTKA";
export var locationIconWord = "index-module--locationIconWord--ZXBl3";
export var topicBannerWrapper = "index-module--topicBannerWrapper--VMw2+";
export var wordImg = "index-module--wordImg--a7Slb";
export var words = "index-module--words--1hR1i";