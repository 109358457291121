import React, { useState, useEffect, CSSProperties } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface DetailedExpansionCardProps {
  /**卡片数组 */
  cardArr?: any
}

interface ExtendedCSSProperties extends CSSProperties {
  /** 背景图片 */
  '--bg'?: string
  /**按钮图片 */
  '--btn'?: string
}

const DetailedExpansionCard: React.FC<DetailedExpansionCardProps> = (props) => {
  const { cardArr } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const isMb = useIsWindowWidthSmaller()

  useEffect(() => {
    if (isMb) {
      setActiveIndex(0)
    }
  }, [isMb])

  return (
    <div className={styles.detailedExpansionCard}>
      {cardArr.map((item, index) => {
        const style: ExtendedCSSProperties = {
          '--btn': `url(${isMb ? item.btnBgMb.publicURL ?? item.btnBgMb : item.btnBg.publicURL ?? item.btnBg})`,
        }

        if (index === activeIndex) {
          const bgStyle: ExtendedCSSProperties = {
            '--bg': `url(${isMb ? item.mbBg.publicURL ?? item.mbBg : item.expansionBg.publicURL ?? item.expansionBg})`,
          }
          return (
            <div
              className={classnames(styles.expansionCard, styles.space)}
              style={{ ...style, ...bgStyle }}
              key={item.title}
              onMouseEnter={
                isMb
                  ? undefined
                  : () => {
                      setActiveIndex(index)
                    }
              }
            >
              {isMb ? (
                <div className={styles.headerMb}>
                  <div className={styles.serialWrapper}>
                    <div className={styles.serial}>{item.serial}</div>
                    <div className={styles.title}>{item.title}</div>
                  </div>
                  <div className={styles.desc}>{item.desc}</div>
                </div>
              ) : (
                <div className={styles.header}>
                  <div className={styles.serial}>{item.serial}</div>
                  <div className={styles.words}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.desc}>{item.desc}</div>
                  </div>
                </div>
              )}
              <div className={styles.content}>{item.content}</div>
              <div className={classnames(styles.btn, { [styles.lastBtn]: index === cardArr.length - 1 })}>
                <a href={item.btnLink} target="_blank"></a>
              </div>
            </div>
          )
        } else {
          const bgStyle: ExtendedCSSProperties = {
            '--bg': `url(${isMb ? item.mbBg.publicURL ?? item.mbBg : item.defaultBg.publicURL ?? item.defaultBg})`,
          }
          return (
            <div
              className={classnames(styles.defaultCard, styles.space)}
              style={{ ...style, ...bgStyle }}
              key={item.title}
              onMouseEnter={
                isMb
                  ? undefined
                  : () => {
                      setActiveIndex(index)
                    }
              }
            >
              <div className={isMb ? styles.headerMb : ''}>
                <div className={styles.serialWrapper}>
                  <div className={styles.serial}>{item.serial}</div>
                  <div className={styles.title}>{item.title}</div>
                </div>
              </div>
              <div className={styles.desc}>{item.desc}</div>
              <div className={styles.content}>{item.content}</div>
              <div className={classnames(styles.btn, { [styles.lastBtn]: index === cardArr.length - 1 })}>
                <a href={item.btnLink} target="_blank"></a>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default DetailedExpansionCard
